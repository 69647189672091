<template>
    <div class="contactanos" id="contact">
        <div class="container">
            <div class="row">
                <div class="col-12 padd-left-200 padd-right-200">
                    <h3>{{$t('landing.contact')}}</h3>
                    <p>{{$t('landing.contactdesc')}}</p>
                </div>
                <div class="col-12 padd-left-100 padd-right-100">
                    <card type="contact">
                        <div class="row">
                            <div class="col-12 col-md-8">
                                <h4>{{$t('landing.msn')}}</h4>
                                <div class="form-contact">
                                    <ValidationObserver ref="form" tag="form" v-slot="{ handleSubmit }">
                                        <form @submit.prevent="handleSubmit(onSubmit)">
                                            <div class="row">
                                                <div class="col-12 col-lg-6">
                                                    <text-input type="text" name="Nombre" :placeholder="$t('landing.placeholder1')" v-model="name" rules="required" :label="$t('landing.label1')"></text-input>
                                                </div>
                                                <div class="col-12 col-lg-6">
                                                    <text-input type="email" name="E-mail" v-model="email" :placeholder="$t('landing.placeholder2')"  rules="required|email" :label="$t('landing.label2')"></text-input>
                                                </div>
                                                <div class="col-12 col-lg-6">
                                                    <text-input type="text" name="Asunto" v-model="asunto" :placeholder="$t('landing.placeholder3')" rules="required" :label="$t('landing.label3')"></text-input>
                                                </div>
                                                <div class="col-12 col-lg-6">
                                                    <combo-box :options="options" name="Sector" inputId="Seleccv"  v-model="sector"  :label="$t('landing.label4')" rules="required"  :placeholder="$t('landing.placeholder4')" ></combo-box>
                                                </div>
                                                <div class="col-12">
                                                    <TexTarea name="Mensaje" id="prueba" v-model="mensaje" :placeholder="$t('landing.placeholder5')" rules="required" :label="$t('landing.label5')"></TexTarea>
                                                    <button type="submit" class="btn btn-bextsocial" >{{$t('landing.btncontact')}}</button>
                                                </div>
                                            </div>
                                        </form>
                                    </ValidationObserver>
                                </div>
                            </div>
                            <div class="col-12 col-md-4">
                                <div class="information">
                                    <h4>{{$t('landing.info')}}</h4>
                                    <p class="direc">
                                        {{$t('landing.dir')}}
                                    </p>
                                    <p class="tele">
                                        (57-1) 744 29 67
                                    </p>
                                    <p class="correo">
                                        mercadeo@bextsa.com
                                    </p>
                                    <a href="https://www.facebook.com/BEXTechnology" title="Dirigite a Facebook" acceskey="U">
                                        <font-awesome-icon :icon="['fab', 'facebook-f']" size="3x"/>
                                    </a>
                                    <a href="https://twitter.com/Bextechnology" title="Dirigite a Twitter" acceskey="U">
                                        <font-awesome-icon :icon="['fab', 'twitter']" size="3x"/>
                                    </a>
                                    <a href="https://www.linkedin.com/company/bextechnology/" title="Dirigite a Linkedin" acceskey="U">
                                        <font-awesome-icon :icon="['fab', 'linkedin-in']" size="3x"/>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </card>
                </div>
            </div>
        </div>
        <notifications group="foo" position="bottom right" />
    </div>
</template>
<script>
import Card from "../card/Card.component";
import TextInput from "../inputForms/InputForms.component";
import TexTarea from "../texTarea/TextareaForms.component";
import ComboBox from "../combobox/ComboBox.component";
import {mapMutations} from "vuex";
export default {
  name: "Contáctenos",
  components: {
    Card,
    TextInput,
    TexTarea,
    ComboBox,
  },
  data() {
    return {
      email: '',
      name: "",
      mensaje: "",
      asunto: "",
      sector: "",
      options:[
          "Agropecuario",
          "Servicios",
          "Industrial",
          "Transporte",
          "Comercio",
          "Construcción",
          "Financiero",
          "Minero y Energético",
          "Comunicaciones",
      ],
    }
  },
  methods: {
      ...mapMutations(["Carga"]),
    async onSubmit () {
    //   alert('Form has been submitted!');
        this.Carga();
        try {
            let axiosConfig = {
                headers: {
                    'Content-Type': 'application/json'
                }
            };
            console.log(this.axios.headers);
            const response = await this.axios.post('/bextsocial/mails', {
                name: this.name,
                email: this.email.trim(),
                asunto: this.asunto,
                sector: this.sector,
                mensaje: this.mensaje
            }, axiosConfig);
            console.log(response);
            if(response.status == 200){
                this.Carga();
                this.name = ""
                this.email = "";
                this.asunto = "";
                this.sector = "";
                this.mensaje = "";
                this.$notify({
                    group: 'foo',
                    type: 'success',
                    title: '',
                    text: 'Mensaje Enviado',
                    duration: 5000,
                    speed: 1000
                })
            }
        } catch (error) {
            this.Carga();
            console.log(error)
            this.$notify({
                    group: 'foo',
                    type: 'error',
                    title: 'Networking',
                    text: error,
                    duration: 5000,
                    speed: 1000
                })
        }
        
    }
  },
  mounted() {
    //   this.hola = this.$refs.form
  },
};
</script>
<style lang="scss" scope>
  
</style>