<template>
  <div class="whybext">
    prueba
    <div class="container">
      <div class="row">
        <div class="col-12">
          <h2 class="borderbottom"> {{$t('landing.por.title')}} </h2>
          <p class="plr-9">{{$t('landing.por.despor')}}</p>
        </div>
        <div class="col-12 col-lg-3">
          <div class="whysocial">
            <span class="fas fa-heart"></span>
            <h3> {{$t('landing.por.poritem1')}} </h3>
            <p> {{$t('landing.por.pordes1')}} </p>
          </div>
        </div>
        <div class="col-12 col-lg-3">
          <div class="whysocial">
            <span class="fas fa-comment-alt"></span>
            <h3> {{$t('landing.por.poritem2')}} </h3>
            <p> {{$t('landing.por.pordes2')}} </p>
          </div>
        </div>
        <div class="col-12 col-lg-3">
          <div class="whysocial">
            <span class="fas fa-file-medical-alt"></span>
            <h3> {{$t('landing.por.poritem3')}} </h3>
            <p> {{$t('landing.por.pordes3')}} </p>
          </div>
        </div>
        <div class="col-12 col-lg-3">
          <div class="whysocial">
            <span class="fas fa-user-friends"></span>
            <h3> {{$t('landing.por.poritem4')}} </h3>
            <p> {{$t('landing.por.pordes4')}} </p>
          </div>
        </div>
        <!-- <div class="col-12">
          <div class="influencer padd-left-110 padd-right-110">
            <h4>{{$t('landing.influe')}}</h4>
            <p>{{$t('landing.influellegar')}}</p>
          </div>
        </div> -->
      </div>
    </div>
    <div class="aument">
      <div class="container">
        <div class="row">
          <div class="col-12 col-lg-6">
            <h2>{{$t('landing.aument.titleaument')}}</h2>
            <p>{{$t('landing.aument.desaument')}}</p>
            <h2>{{$t('landing.aument.titleanal')}}</h2>
            <p>{{$t('landing.aument.desanali')}}</p>
            <button  @click="scrollTop()" title="Dirigite a solicitar una demo" type="button" class="btn btn-bextsocial"> {{$tc('landing.button')}} </button>
          </div>
          <div class="col-12 col-lg-6">
            <img alt="graficas de estadisticas" src="../../assets/image/estadisticas.png"/> 
            <img alt="graficas de sentimientos" src="../../assets/image/sentimientos.png"/>
          </div>
        </div>
      </div>
      
    </div>
    <div class="sociallisting">
      <div class="container">
        <div class="row">
            <div class="col-12">
            <h2 class="borderbottom"> {{$t('landing.list')}} </h2>
          </div>
          <div class="col-12 col-md-6 mt-4">
            <card type="why default">
                <template slot="header">
                  <h4 class="title-button">{{$t('landing.card1title')}}</h4>
                </template>
                <p>{{$t('landing.card1desc')}}</p>
            </card>
          </div>
          <div class="col-12 col-md-6 mt-4">
            <card type="why default">
                <template slot="header">
                  <h4 class="title-button">{{$t('landing.card2title')}}</h4>
                </template>
                <p>{{$t('landing.card2desc')}}</p>
            </card>
          </div>
          <div class="col-12 col-md-6 mt-4">
            <card type="why default">
                <template slot="header">
                  <h4 class="title-button">{{$t('landing.card3title')}}</h4>
                </template>
                <p>{{$t('landing.card3desc')}}</p>
            </card>
          </div>
          <div class="col-12 col-md-6 mt-4">      
            <card type="why default">
              <template slot="header">
                <h4 class="title-button">{{$t('landing.card4title')}}</h4>
              </template>
              <p>{{$t('landing.card4desc')}}</p>
            </card>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Card from "../card/Card.component";
//import { mapMutations } from "vuex";
export default {
  name: "PorQueBextSocial",
  components: {
    Card,
  },
  data() {
    return {
      modalObjet:{
          typeModal : null,
          icon: null,
          mssg: null,
          title: null,
          showModal: null,
      },
      showFeedbackModal: false,
    }
  },
  
  methods: {
    
  },
};
</script>
<style lang="scss">
  .custom-template {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  text-align: left;
  font-size: 13px;
  margin: 5px;
  margin-bottom: 0;
  align-items: center;
  justify-content: center;
  &, & > div {
    box-sizing: border-box;
  }
  background: #E8F9F0;
  border: 2px solid #D0F2E1;
  .custom-template-icon {
    flex: 0 1 auto;
    color: #15C371;
    font-size: 32px;
    padding: 0 10px;
  }
  .custom-template-close {
    flex: 0 1 auto;
    padding: 0 20px;
    font-size: 16px;
    opacity: 0.2;
    cursor: pointer;
    &:hover {
      opacity: 0.8;
    }
  }
  .custom-template-content {
    padding: 10px;
    flex: 1 0 auto;
    .custom-template-title {
      letter-spacing: 1px;
      text-transform: uppercase;
      font-size: 10px;
      font-weight: 600;
    }
  }
}
</style>