<template>
    <div class="content">
        <landing />
        <monitoreo />
        <contactenos/>
    </div>
</template>

<script>
import Landing from "@/components/landing/Landing.component.vue";
import Monitoreo from "@/components/landing/Monitoreo.component.vue";
import Contactenos from '@/components/contactanos/Contactenos.component.vue';
export default {
    name: "LandingVue",
    components: {
    Landing,
    Monitoreo,
    Contactenos
  },
}
</script>