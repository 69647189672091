<template>
  <div class="form-group">
    <ValidationProvider :name="name" :rules="rules" v-slot="{classes,errors}">
        <div class="control" :class="classes">
            <label :for="name">{{label}}</label>
            <textarea :name="name" :placeholder="placeholder" :value="value" ref="input" :id="id" rows="30" cols="50" @input="updateValue($event.target.value)">
            </textarea>
            <span>{{ errors[0] }}</span>
        </div>
    </ValidationProvider>
  </div>
</template>

<script>
import { extend } from 'vee-validate';
import { required, email } from 'vee-validate/dist/rules';
extend('required', {
  ...required,
  message: (field, values) => 'Por favor coloque un dato en el campo ' + `${field}`
});
export default {
  name: 'TextTarea',
  props: {
    name: String,
    label: String,
    rules: String,
    id: String,
    value: String,
    placeholder: String
  },
  mounted () {
    // this.$el.value = this.value;
  },
  methods: {
    updateValue(value){
      this.$refs.input.value = value;
      this.$emit('input', value);
          
       // Firing an event via Event bus to notify sibling reusable component
    }
  },  
}
</script>